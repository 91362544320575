import { Grid } from "@material-ui/core";

import React from "react";
import { Layout } from "../components/Layout";

import appPreview from "../images/preview.png";
import { Navbar } from "../components/Navbar";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Navbar
        title="Find Local Keto Places Near You"
        subtitle="Find your next bite with Local Keto!"
      />
      <Grid container justify="center">
        <Grid item>
          <img style={{ width: 400, marginTop: "5rem" }} src={appPreview} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
